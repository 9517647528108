import type { IDoctor, ClinicInterface } from '~/EastclinicVueApi';

export const useDoctorsStore = defineStore('doctorsStore', () => {
  const doctorsCacheMap = ref<Map<number, IDoctor>>(new Map());
  const workDayCacheMap = ref<Map<number, number>>(new Map());
  const selectedClinicCacheMap = ref<Map<number, ClinicInterface>>(new Map());
  const count = ref<number>(0);
  const doctorInfoEl = ref(null);
  const doctorReviewsCount = ref(0);

  const doctorInfoIsVisible = useElementVisibility(doctorInfoEl);

  const setWorkDay = (idDoctor: number, day: number) => {
    workDayCacheMap.value.set(idDoctor, day);
  };
  const getWorkDay = (idDoctor: number) => {
    return workDayCacheMap.value.get(idDoctor);
  };

  const setSelectedClinic = (idDoctor:number, clinic: ClinicInterface|null|undefined = null) => {
    if (!clinic) {
      selectedClinicCacheMap.value.delete(idDoctor);
    } else {
      selectedClinicCacheMap.value.set(idDoctor, clinic);
    }
  };
  const getSelectedClinic = (idDoctor: number):ClinicInterface|null => {
    return (selectedClinicCacheMap.value.get(idDoctor)) ?? null;
  };

  const setCount = (countDoctors:number) => {
    count.value = countDoctors;
  };

  return {
    doctorsCacheMap,
    workDayCacheMap,
    selectedClinicCacheMap,
    count,
    doctorInfoEl,
    doctorInfoIsVisible,
    doctorReviewsCount,
    setWorkDay,
    getWorkDay,
    setCount,
    setSelectedClinic,
    getSelectedClinic
  };
});
