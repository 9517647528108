<script setup lang="ts">
  import { usePatientStore } from '~/EastclinicVueApi';

  const statePatient = usePatientStore();
  
</script>

<template>
  <div class="booking__dialog__item">
    <div class="booking__dialog__card" :class="{'error-border': !!statePatient.phoneError.value}">
      <input
        id="phone"
        v-model="statePatient.patientData.phone"
        v-mask="'+# (###) ###-##-##'"
        type="tel"
        name="phone"
        :class="{'text-color-main': statePatient.isFilledPhone}"
        placeholder="Номер телефона"
      >
    </div>
    <span class="input-error-message" v-if="statePatient.phoneError.value">{{ statePatient.phoneError.value }}</span>
  </div>
</template>
