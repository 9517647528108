<script setup lang="ts">
  const props = defineProps<{
    loading?:boolean
    disable?:boolean
  }>();

  onMounted(() => {});

// todo debug click outside
</script>

<template>
  <button
    v-bind="$attrs"
    type="button"
    class="button large"
    style="position: relative;"
    :class="{'disable': disable}"
    :disabled="disable"
  >
    <span v-if="loading" class="button-loader"></span>
    <slot v-else name="default"></slot>
  </button>
</template>

<style scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #fff;
    padding: 20px;

}
/* HTML: <div class="loader"></div> */
.button-loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 .4s infinite linear alternate;
}
@keyframes l5 {
    0%  {box-shadow: 20px 0 #2D7FF9, -20px 0 #86a7fa;background: #2D7FF9 }
    33% {box-shadow: 20px 0 #2D7FF9, -20px 0 #86a7fa;background: #86a7fa}
    66% {box-shadow: 20px 0 #86a7fa,-20px 0 #2D7FF9; background: #86a7fa}
    100%{box-shadow: 20px 0 #86a7fa,-20px 0 #2D7FF9; background: #2D7FF9 }
}
</style>
